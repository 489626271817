<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Dashboard</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row> </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import axios from "@axios";
export default {
  name: "Home",
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VueApexCharts,
  },
};
</script>
